/**
* This function will determine if the ai is unsure about the answer or user has used inappropriate language
*/
export const validateAiResponse = (aiResponse: any) => {
    const { gptAnswer, intent, isValidResponse, scenario } = aiResponse;
    switch (intent) {
        case 'query':
            return { valid: isValidResponse, case: isValidResponse ? '' : 'unsure', gptAnswer };
        case 'offensive':
        case 'userdefined_intent':
            return { valid: false, case: 'userdefined_intent', gptAnswer, scenario };
        default:
            return { valid: true, case: '', gptAnswer };
    }
};

export const handleUnsureAiResponseMethod = async (aiResponse: any, messages: any) => {
    try {
        const { gptAnswer } = aiResponse;
        let aiConfiguredMessages = messages;

        let finalQuestion;
        if (aiConfiguredMessages?.unusualChatHandling?.unableToAnswer?.invokeNewFlow.enabled) {

            finalQuestion = {
                type: 'userdefined_intent',
                trigger: 'flow',
                _flow: aiConfiguredMessages?.unusualChatHandling?.unableToAnswer?.invokeNewFlow?.flowId
            }

        } else if (
            aiConfiguredMessages?.unusualChatHandling?.unableToAnswer?.showErrorMessage &&
            aiConfiguredMessages?.unusualChatHandling?.unableToAnswer?.showLiveChatButton?.enabled
        ) {
            const errorMessage = aiConfiguredMessages.noAnswer;
            const buttonName = aiConfiguredMessages?.unusualChatHandling?.unableToAnswer?.showLiveChatButton?.text;
            // final message will be returned like this
            finalQuestion = {
                type: 'button',
                message: {
                    type: 'button',
                    label: errorMessage,
                    options: [
                        {
                            type: 'button',
                            value: buttonName,
                            _id: undefined,
                            next: {
                                type: 'live_chat',
                                target: '',
                            },
                        },
                    ],
                },
            };
        } else if (
            aiConfiguredMessages?.unusualChatHandling?.unableToAnswer?.showErrorMessage &&
            aiConfiguredMessages?.unusualChatHandling?.unableToAnswer?.invokeNewMessageInFlow?.enabled
        ) {
            // invoke the configured message in the flow
            const questionId = aiConfiguredMessages?.unusualChatHandling?.unableToAnswer?.invokeNewMessageInFlow?.questionId;
            finalQuestion = {
                type: 'question',
                message: {
                    label: aiConfiguredMessages.noAnswer,
                    next: { type: 'question', target: questionId },
                },
            };
        } else if (aiConfiguredMessages?.unusualChatHandling?.unableToAnswer?.showLiveChatButton?.enabled) {
            // if user has enabled only for live chat then sending gpt response with live chat option
            const buttonName = aiConfiguredMessages?.unusualChatHandling?.unableToAnswer?.showLiveChatButton?.text;
            // final message will be returned like this
            finalQuestion = {
                type: 'button',
                message: {
                    type: 'button',
                    label: gptAnswer,
                    options: [
                        {
                            type: 'button',
                            value: buttonName,
                            _id: undefined,
                            next: {
                                type: 'live_chat',
                                target: '',
                            },
                        },
                    ],
                },
            };
        } else {
            // if user has enabled to show only error message
            finalQuestion = { type: 'text', message: aiConfiguredMessages.noAnswer };
        }
        return finalQuestion;
    } catch (error) {
        console.log(error);
    }

};