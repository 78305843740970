export const NEW_MESSAGE = 'NEW_MESSAGE';
export const UPDATE_MESSAGES = 'UPDATE_MESSAGES';
export const UPDATE_SINGLE_MESSAGES = 'UPDATE_SINGLE_MESSAGES';
export const RESET_MESSAGES = 'RESET_MESSAGES';
export const LOAD_MESSAGE = 'LOAD_MESSAGE';
export const UPDATE_TEXT_INPUT = 'UPDATE_TEXT_INPUT';
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const START_TYPING = 'START_TYPING';
export const STOP_TYPING = 'STOP_TYPING';
export const RESTART_CHAT = 'RESTART_CHAT';
export const DISABLE_REFRESH = 'DISABLE_REFRESH';
export const ENABLE_REFRESH = 'ENABLE_REFRESH';
export const UPDATE_SKIP_BACK = 'UPDATE_SKIP_BACK';
export const SHOW_ALERT = 'SHOW_ALERT';
export const START_LIVE_CHAT = 'START_LIVE_CHAT';
export const STOP_LIVE_CHAT = 'STOP_LIVE_CHAT';
export const ENABLE_WHATSAPP = 'ENABLE_WHATSAPP';
export const DISABLE_WHATSAPP = 'DISABLE_WHATSAPP';
export const SET_TRANSLATION = 'SET_TRANSLATION';
export const SET_SESSION_ID = 'SET_SESSION_ID';
export const SET_WHITE_LABEL = 'SET_WHITE_LABEL';
export const UPDATE_ENVIRONMENT = 'UPDATE_ENVIRONMENT';
export const LOAD_FLOW = 'LOAD_FLOW';
export const UPDATE_FLOW = 'UPDATE_FLOW';
export const UPDATE_META = 'UPDATE_META';
// TODO: Remove
export const TYPING_START = 'TYPING_START';
export const TYPING_STOP = 'TYPING_STOP';
export const USER_TYPING = 'USER_TYPING';
export const AI_REPLIES = 'AI_REPLIES';
export const AI_KEY_ACTIVE = 'AI_KEY_ACTIVE';
export const UPDATE_APPOINTMENT_META = 'UPDATE_APPOINTMENT_META';
export const ADD_ATTRIBUTE = 'ADD_ATTRIBUTE';
export const ADD_ATTRIBUTES = 'ADD_ATTRIBUTES';
export const AI_THREAD_ID = 'AI_THREAD_ID';
export const AI_INITIALIZE = 'AI_INITIALIZE';
export const UPDATE_CONFIGURATION = 'UPDATE_CONFIGURATION';
export const UPDATE_DESIGN = 'UPDATE_DESIGN';
export const RATING_SCREEN = 'RATING_SCREEN';
export const LIVE_CHAT_ACCEPTED = 'LIVE_CHAT_ACCEPTED';
export const SYSTEM_MESSAGE = 'SYSTEM_MESSAGE';
export const UPDATE_BOT_HANDLER = 'UPDATE_BOT_HANDLER';
export const INITIALIZE_LANDING_PAGE = 'INITIALIZE_LANDING_PAGE';
export const UPDATE_REPLY_MESSAGE = 'UPDATE_REPLY_MESSAGE';

export const ADD_KNOWLEDGE_BASE = 'ADD_KNOWLEDGE_BASE';
export const GET_KNOWLEDGE_BASE = 'GET_KNOWLEDGE_BASE';
export const SET_FOOTER_TAB = 'SET_FOOTER_TAB';
export const RESET_KNOWLEDGE_BASE = 'RESET_KNOWLEDGE_BASE';
export const SET_KNOWLEDGE_BASE_URL = 'SET_KNOWLEDGE_BASE_URL';
export const SET_KNOWLEDGE_BASE_SUBPAGE = 'SET_KNOWLEDGE_BASE_SUBPAGE';
export const ROUTE_KNOWLEDGE_BASE = 'ROUTE_KNOWLEDGE_BASE';
export const ACTIVE_PAGE_ID = 'ACTIVE_PAGE_ID';

export const LOAD_MESSAGES = 'LOAD_MESSAGES';
export const LOAD_ENVIRONMENT = 'LOAD_ENVIRONMENT';
export const LOAD_TEXT_INPUT = 'LOAD_TEXT_INPUT';
export const LOAD_APPOINTMENT_META = 'LOAD_APPOINTMENT_META';
export const LOAD_AI_REPLIES = 'LOAD_AI_REPLIES';

export const UPDATE_REDIRECT_FLOW = 'UPDATE_REDIRECT_FLOW';
export const LOAD_REVISIT_FLOW = 'LOAD_REVISIT_FLOW';
export const RESET_STREAMING_STATE= 'RESET_STREAMING_STATE';
export const UPDATE_AI_ENVIRONMENT= 'UPDATE_AI_ENVIRONMENT';