import { getBotFlows, scroll } from './shared';
import { STORE } from '../store';
import { addSystemMessage, newMessage, updateFlow, updateSingleMessage, updateTextInput } from '../actions';
import { updateAiEnvironment, updateEnvironment } from '../actions/environment.action';
import { io } from 'socket.io-client';
import { clearRequestTimer, expireLiveChatRequest, renderNextQuestion, transferToBot } from '../utilities/LiveChat';
import { updateBotHandler } from '../actions/botHandler';
import { newMessageSound } from './notification-handler';

let typingDebounceTimer: any;
let socket: any;
let expireTransferChatTimer: any;

export const createSocketConnection = async (meta: any) => {
	try {
		const { environment, configuration, design } = STORE.getState();

		socket = io(`${process.env.REACT_APP_API_URL}`, {
			path: '/ws/chatbot', query: {
				host: window.location.hostname,
				url: window.location.href,
				...meta
			},
			reconnectionAttempts: 15,
			reconnectionDelayMax: 5000
		});

		socket.on('connected', () => {
		
			socket.emit('update-user-details', {
				configuration: {
					...configuration,
					triggerSettings: undefined,
					userAccessSettings: undefined,
					seoSettings: undefined,
					name: design.name
				},
				//   isRevisit: !!isRevisit
			});
			localStorage.setItem('revisited', 'bp');
			localStorage.setItem('socketDisconnected', "false");
			STORE.dispatch(updateEnvironment({
				socketDisconnected: false,
			}));
			STORE.dispatch(updateBotHandler('BOT'));
		});

		socket.on('error', console.log);

		socket.on('message', (message: any) => {

			STORE.dispatch(newMessage({
				mid: message.mid,
				label: message.text,
				position: 'left',
				type: 'question',
				messageBy: message.messagedBy,
				_parent: message._parent,
				createdAt: new Date().toISOString(),
				...(message.medias && { medias: message.medias, type: message.type })
			}));
			newMessageSound(configuration.generalSettings.notificationSound);
			scroll(message?.id + ' ' + (message.label || '').slice(-10));
		});

		socket.on('disconnect', function () {
			STORE.dispatch(updateBotHandler('BOT'));

			STORE.dispatch(updateEnvironment({
				socketDisconnected: true,
			}));
			if (localStorage.getItem('agentId')) {
				transferToBot();
			}
		});

		socket.on('chat-request-accepted', (payload: any) => {
			localStorage.setItem('agentId', payload.agentId);
			if (payload.whatsappNumber.number && payload.whatsappNumber.prefix) {
				localStorage.setItem('bp-wsn', `+${payload.whatsappNumber.prefix}${payload.whatsappNumber.number}`);
			}
			clearRequestTimer();
			if (expireTransferChatTimer) {
				clearTimeout(expireTransferChatTimer);
			}
			STORE.dispatch(updateEnvironment({
				chatRequestRejected: false,
				disableStartButton: false,
				chatRequestAccepted: true,
				agentId: payload.agentId,
				liveChat: true,
				agentAvatar: payload.picture,
				agentName: payload.name
			}));
			STORE.dispatch(updateTextInput({
				status: true,
			}));

			STORE.dispatch(addSystemMessage({
				method: 'chat-request-accepted',
				title: configuration.generalSettings.unavailabilityMessageTitle,
				subTitle: configuration.generalSettings.unavailabilityMessage,
				agentName: payload.name
			}));

			scroll();
			STORE.dispatch(updateBotHandler('AGENT'));
		})

		socket.on('no-agent-found', (payload: any) => {
			if (payload.uuid === environment.uuid) {
				clearRequestTimer();
				expireLiveChatRequest(environment.uuid);

				STORE.dispatch(addSystemMessage({
					method: 'no-agent-found',
					title: configuration.generalSettings.unavailabilityMessageTitle,
					subTitle: configuration.generalSettings.unavailabilityMessage,
					titleColor: design.themeColor
				}));
				STORE.dispatch(updateEnvironment({
					liveChatExpired: true,
					chatRequestRejected: true,
					chatRequestAccepted: false,
					noAgentFound: true,
					liveChat: false,
				}));
				renderNextQuestion()
			}
		})


		socket.on('typing-start', (payload: any) => {
			if (localStorage.getItem('agentId') && payload === environment.uuid) {
				STORE.dispatch(updateEnvironment({
					typing: true,
				}));
				scroll();
				setTimeout(() => {
					STORE.dispatch(updateEnvironment({
						typing: false,
					}));
				}, 3000)
			}
		})

		socket.on('typing-stop', (payload: any) => {
			if (localStorage.getItem('agentId') && payload === environment.uuid) {
				STORE.dispatch(updateEnvironment({
					typing: false,
				}));
			}
		})

		socket.on('chat-transferred', (payload: any) => {
			if (payload.mode === 'BOT') {
				STORE.dispatch(updateBotHandler('BOT'));
				STORE.dispatch(updateEnvironment({
					liveChat: false,
					agentId: undefined,
					chatRequestAccepted: false,
					disableStartButton: false,
				}));
				localStorage.removeItem('agentId');
				localStorage.removeItem('bp-wsn');

				if (payload._flow) {
					getBotFlows({ type: 'redirect', next: { target: payload._flow } })
						.then((flow) => {
							STORE.dispatch(updateFlow([flow]));
							renderNextQuestion();
						})
						.catch((err) => console.log(err));
				} else {
					renderNextQuestion();
				}
			} else if (payload.mode === 'WHATSAPP') {
				STORE.dispatch(updateEnvironment({
					liveChat: false,
					agentId: undefined,
					chatRequestAccepted: false,
					disableStartButton: false,
				}));

				STORE.dispatch(updateTextInput({
					status: false,
					value: ''
				}));
				localStorage.removeItem('agentId');
				localStorage.removeItem('bp-wsn');

				scroll();
			}

		})

		/**
		 * Author: Satyam Sharma
		 * Date: 31-12-2024
		 * Description: Socket to stream AI responses. Currently working for ChatGPT.
		 * This function handles a real-time stream of tokens from a ChatGPT-like API. 
		 * It buffers out-of-order tokens, updates the sequence state, and 
		 * dispatches actions to update or create AI-generated messages in the Redux store.
		 */
		socket.on('chat-gpt-stream', (payload: any) => {
			const { mid, token, sequence, lastExpectedSequence } = payload;
			const state = STORE.getState().environment;

			let { ai } = state;
			let { sequenceBuffer, nextExpectedSequence, lastExpectedSequenceInternal, streaming } = ai;
			
			if (lastExpectedSequence) {
				lastExpectedSequenceInternal = lastExpectedSequence;
				STORE.dispatch(updateAiEnvironment({ lastExpectedSequenceInternal }));
			}

			// Store the token in the buffer
			const updatedSequenceBuffer = { ...JSON.parse(JSON.stringify(sequenceBuffer)), [sequence]: token };


			while (updatedSequenceBuffer.hasOwnProperty(nextExpectedSequence)) {
				const currentToken = updatedSequenceBuffer[nextExpectedSequence];
				delete updatedSequenceBuffer[nextExpectedSequence];
				const messages = STORE.getState().messages;
				const exists = messages.find((message: any) => message.mid === mid);
				if (exists) {
					if (streaming) {
						STORE.dispatch(updateSingleMessage({
							label: !!currentToken ? exists.label + currentToken : exists.label,
							position: 'left',
							type: 'ai',
							mid: mid,
							shouldFeedbackVisible: true
						}));
					}

				} else {

					STORE.dispatch(updateEnvironment({ typing: false }));
					STORE.dispatch(updateAiEnvironment({ streaming: true }));
					STORE.dispatch(newMessage({
						label: currentToken,
						position: 'left',
						type: 'ai',
						mid: mid,
						shouldFeedbackVisible: true
					}));
				}

				STORE.dispatch(updateEnvironment({ typing: false }));
				STORE.dispatch(updateAiEnvironment({
					sequenceBuffer: { ...updatedSequenceBuffer },
					nextExpectedSequence: nextExpectedSequence + 1
				}));
			}

			if (lastExpectedSequenceInternal && (nextExpectedSequence <= lastExpectedSequenceInternal)) {
					Object.keys(updatedSequenceBuffer)
						.sort((a, b) => Number(a) - Number(b))
						.forEach((key) => {
							const remainingToken = updatedSequenceBuffer[key];
							const messages = STORE.getState().messages;
							const exists = messages.find((message: any) => message.mid === mid);

							if (exists) {
								STORE.dispatch(updateSingleMessage({
									label: !!remainingToken ? exists.label + remainingToken : exists.label,
									position: 'left',
									type: 'ai',
									mid: mid,
									shouldFeedbackVisible: true
								}));
							} else {
								STORE.dispatch(newMessage({
									label: remainingToken,
									position: 'left',
									type: 'ai',
									mid: mid,
									shouldFeedbackVisible: true
								}));
							}

							delete updatedSequenceBuffer[key];
						});

					STORE.dispatch(updateEnvironment({ typing: false }));
					STORE.dispatch(updateAiEnvironment({
						sequenceBuffer: {},
						nextExpectedSequence: 0,
						lastExpectedSequenceInternal: -1,
						streaming: false
					}))
			}

			STORE.dispatch(updateAiEnvironment({ sequenceBuffer: { ...updatedSequenceBuffer } }));

			

		});

	} catch (error) {
		console.log(error, "Error in socket connection");
	}
}

export const emit = (event: any, payload: any) => {
	if (socket && socket.connected) {
		socket.emit(event, payload);
	}
};

export const updateMetaToSocket = () => {
	const { configuration, design } = STORE.getState();

	socket.emit('update-user-details', {
		configuration: {
			...configuration,
			triggerSettings: undefined,
			userAccessSettings: undefined,
			seoSettings: undefined,
			name: design.name
		},
		//   isRevisit: !!isRevisit
	});
};

export const advanceMessage = (payload: any) => {
	if (typingDebounceTimer) {
		clearTimeout(typingDebounceTimer);
	}

	typingDebounceTimer = setTimeout(() => {
		if (localStorage.getItem('agentId')) {
			socket.emit('adv-message', {
				uuid: payload.uuid,
				advanceMessage: payload.advanceMessage,
				agent: localStorage.getItem('agentId')
			})
		}
	}, 500);
}
