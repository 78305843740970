import { updateEnvironment } from "../actions/environment.action";
import { STORE } from "../store";
import { httpRequest } from "./network";
import { encryptPayload } from "./saveResponse";

export const getBotById = async (botId: string, userId: string, uuid?: string) => {
	try {
		const widgetData: any = await httpRequest('GET', `website-widget/${userId}/${botId}?uuid=${uuid ?? 'null'}`)
		return widgetData.data;
	} catch (error) {
		throw new Error('Error occured while fetching widget');
	}
}

export const setUserRating = async (index: number) => {
	try {
		const widgetData: any = await httpRequest('PUT', `user/rating`, {
			binary: encryptPayload({
				uuid: localStorage.getItem('BotPenguin_User_uuid'),
				rating: index
			})
		});
		return widgetData.data;
	} catch (error) {
		throw new Error('Error occured while setting rating');
	}
}

export const isValidObjectId = (value: string) => {
	const objectIdPattern = /^[0-9a-fA-F]{24}$/;
	return objectIdPattern.test(value);
};

export const getRephrasedFlow = async (botId: string, flowId: string, type: 'revisit' | 'main' | 'redirect') => {
	const retries = 5;
	const delay = 2000;

	for (let i = 0; i < retries; i++) {
		try {
			const response: any = await httpRequest('GET', `rephrased-flow/${botId}/${flowId}`)

			if (!response || !response.data) {
				throw new Error('Failed to fetch rephrased flow');
			}

			if (type === 'main') {
				STORE.dispatch(updateEnvironment({ rephrasedFlow: response.data || [] }));
			} else if (type === 'redirect') {
				STORE.dispatch(updateEnvironment({ rephrasedRedirectFlow: response.data || [] }));
			} else {
				STORE.dispatch(updateEnvironment({ rephrasedRevisitFlow: response.data || [] }));
			}

			return true;
		} catch (error) {
			if (i === retries - 1) {
				return undefined;
			}

			// Console is kept by intention
			console.log(`Retrying rephrase request... attempt ${i + 1}`,);
			console.log(error);
			// Delay before retrying
			await new Promise(resolve => setTimeout(resolve, delay * (i + 1)));
		}

	}
}
